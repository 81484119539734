import { API } from "../../constans/Url"
import { apiClient } from "../../helper"

const get_AkunList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.AKUN_PERKIRAAN.LIST, params).then(res => {
    if (res.status == 200) {
      callbackSuccess(res.data)
    }else {
      callbackError(res.data)
    }
  }).catch(err =>{
    callbackError(err)
  })
}

const get_AkunDetail = (id, callbackSuccess, callbackError) => {
  apiClient(true).get(API.AKUN_PERKIRAAN.GET, {id: id}).then(res => {
    if (res.status == 200) {
      callbackSuccess(res.data)
    }else {
      callbackError(res.data)
    }
  }).catch(err =>{
    callbackError(err)
  })
}

const post_AkunSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.AKUN_PERKIRAAN.SAVE, params).then(res => {
    if (res.status == 200 || res.status === 201) {
      callbackSuccess(res.data)
    }else {
      callbackError(res.data)
    }
  }).catch(err =>{
    callbackError(err)
  })
}

//tipe akun perkiraan
const get_AkunTipeList = (callbackSuccess, callbackError) =>{
  apiClient(true).get(API.AKUN_PERKIRAAN.TIPE.LIST).then(res => {
    if (res.status == 200) {
      callbackSuccess(res.data)
    }else {
      callbackError(res.data)
    }
  }).catch(err =>{
    callbackError(err)
  })
}

const get_AkunDefault = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.AKUN_PERKIRAAN.DEFAULT, params).then(res => {
    if (res.status == 200) {
      callbackSuccess(res.data)
    }else {
      callbackError(res.data)
    }
  }).catch(err =>{
    callbackError(err)
  })
}

export {
  get_AkunList,
  get_AkunDetail,
  post_AkunSave,
  get_AkunTipeList,
  get_AkunDefault
}