import { API } from "../../constans/Url";
import { apiClient } from "../../helper";

const get_listAccountSetting =  (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.ACCOUNT_SETTING.LIST, params).then(res => {
    if (res.status == 200) {
      callbackSuccess(res.data)
    }else {
      callbackError(res.data)
    }
  }).catch(err =>{
    callbackError(err)
  })
}

const post_saveBulkAccountSetting =  (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.ACCOUNT_SETTING.SAVEBULK, params).then(res => {
    if (res.status == 200) {
      callbackSuccess(res.data)
    }else {
      callbackError(res.data)
    }
  }).catch(err =>{
    callbackError(err)
  })
}

  
export {
  get_listAccountSetting,
  post_saveBulkAccountSetting
}