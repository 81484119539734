<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">{{ label }}</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-departement"
                      >Departemen
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ label_path }}
                  </li>
                </ol>
              </nav>
            </div>

            <div
              class="button-edit"
              v-show="
                validated == 1 && label_path == 'Edit' && hideBtn == false
              "
            >
              <button class="btn-add" type="button" @click="editable">
                <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                Edit
              </button>
            </div>
            <div
              v-show="
                (validated != 1 && label_path == 'Edit') ||
                hideBtn == true ||
                label_path == 'Tambah'
              "
            >
              <button class="btn btn-save" type="submit" :disabled="isSubmit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
              <!-- <button class="btn btn-blue ml-2" type="button" @click="onSync" v-if="enableSync" :disabled="isSubmit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan & Sync
              </button> -->
            </div>
          </div>
          <div class="card info-detail">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="pills-home-tab"
                  data-toggle="pill"
                  href="#pills-home"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  >Detail</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="pills-profile-tab"
                  data-toggle="pill"
                  href="#pills-profile"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  >Penomoran</a
                >
              </li>

              <!-- <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="pills-account-tab"
                  data-toggle="pill"
                  href="#pills-account"
                  role="tab"
                  aria-controls="pills-account"
                  aria-selected="false"
                  >Default Akun Perkiraan</a
                >
              </li> -->
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div class="row mt-3">
                  <div class="col-12">
                    <div class="title">Detail Departemen</div>
                    <div class="sub-title">
                      {{ label_path }} informasi yang diperlukan
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="nama">Nama Departemen : </label>
                      <input
                        :disabled="validated == 1 && label_path == 'Edit'"
                        type="text"
                        class="form-control"
                        id="nama"
                        @keyup="formChange('nama')"
                        v-model="formData.nama"
                        :class="{ 'is-invalid': formError && formError.nama }"
                        placeholder="Masukkan Nama Departemen"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.nama"
                      >
                        {{ formError.nama }}
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="no_telp">Nomor Telepon:</label>
                      <input
                        :disabled="validated == 1 && label_path == 'Edit'"
                        type="text"
                        class="form-control"
                        id="no_telp"
                        v-maska="'####################'"
                        @keyup="formChange('no_telp')"
                        v-model="formData.no_telp"
                        :class="{
                          'is-invalid': formError && formError.no_telp,
                        }"
                        placeholder="Masukkan Nomor Telepon"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.no_telp"
                      >
                        {{ formError.no_telp }}
                      </div>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="form-group">
                      <label for="email">Email :</label>
                      <input
                        :disabled="validated == 1 && label_path == 'Edit'"
                        type="email"
                        class="form-control"
                        id="email"
                        placeholder="Masukkan Email"
                        @keyup="formChange('email')"
                        v-model="formData.email"
                        :class="{ 'is-invalid': formError && formError.email }"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.email"
                      >
                        {{ formError.email }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputAddress2">Status :</label>
                      <Select2
                        :disabled="validated == 1 && label_path == 'Edit'"
                        :options="statusCompany"
                        placeholder="Periode Akuntansi"
                        v-model="formData.status"
                        :class="{ 'is-invalid': formError && formError.status }"
                        :settings="{ minimumResultsForSearch: -1 }"
                        @change="myChangeEvent($event, 'status')"
                        @select="myChangeEvent($event, 'status')"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.status"
                      >
                        {{ formError.status }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="title">Alamat Departemen</div>
                    <div class="sub-title">
                      Tambah alamat departemen yang diperlukan
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="inputAddress2">Provinsi :</label>
                      <Select2
                        :disabled="validated == 1 && label_path == 'Edit'"
                        v-model="formData.id_provinsi"
                        :options="OptionsProvince"
                        placeholder="Provinsi"
                        :class="{
                          'is-invalid': formError && formError.id_provinsi,
                        }"
                        @change="locationEvent($event, 'id_provinsi')"
                        @select="locationEvent($event, 'id_provinsi')"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.id_provinsi"
                      >
                        {{ formError.id_provinsi }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputAddress2">Kabupaten :</label>
                      <Select2
                        :disabled="validated == 1 && label_path == 'Edit'"
                        v-model="formData.id_kabupaten"
                        :options="OptionsRegion"
                        placeholder="Kabupaten"
                        :settings="{
                          settingOption: value,
                          settingOption: value,
                        }"
                        :class="{
                          'is-invalid': formError && formError.id_kabupaten,
                        }"
                        @change="locationEvent($event, 'id_kabupaten')"
                        @select="locationEvent($event, 'id_kabupaten')"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.id_kabupaten"
                      >
                        {{ formError.id_kabupaten }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputAddress2">Kecamatan :</label>
                      <Select2
                        :disabled="validated == 1 && label_path == 'Edit'"
                        v-model="formData.id_kecamatan"
                        :options="OptionsDistrict"
                        placeholder="Kecamatan"
                        :settings="{
                          settingOption: value,
                          settingOption: value,
                        }"
                        :class="{
                          'is-invalid': formError && formError.id_kecamatan,
                        }"
                        @change="locationEvent($event, 'id_kecamatan')"
                        @select="locationEvent($event, 'id_kecamatan')"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.id_kecamatan"
                      >
                        {{ formError.id_kecamatan }}
                      </div>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="form-group">
                      <label for="lokasi">Lokasi :</label>
                      <textarea
                        :disabled="validated == 1 && label_path == 'Edit'"
                        type="text"
                        class="form-control"
                        @keyup="formChange('alamat')"
                        id="lokasi"
                        v-model="formData.alamat"
                        :class="{ 'is-invalid': formError && formError.alamat }"
                        placeholder="Masukkan Lokasi"
                      ></textarea>
                      <div
                        class="form-error"
                        v-if="formError && formError.alamat"
                      >
                        {{ formError.alamat }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div class="row mt-3">
                  <div class="col-7">
                    <div class="title">Penomoran Departemen</div>
                    <div class="sub-title">Edit informasi yang diperlukan</div>
                  </div>
                  <div class="col-5 d-flex justify-content-end align-items-center" v-if="this.label == 'Edit Departemen'">
                    <button
                      class="btn btn-import mr-2"
                      type="button"
                      @click="addNumbering"
                    >
                      Tambah Penomoran
                    </button>
                  </div>
                </div>
                <hr />
                <div
                  class="table-responsive mt-3"
                  v-show="this.label == 'Edit Departemen'"
                >
                  <table
                    class="table table-bordered table-striped table-hover"
                    id="tableHistoryAddress"
                  >
                    <thead>
                      <tr>
                        <th>Nama</th>
                        <th>Tipe Transaksi</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value, index) in listPenomoran"
                        :key="index"
                        v-on:click="detail(value)"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Klik untuk edit"
                      >
                        <td>{{ value.nama }}</td>
                        <td>{{ value.transaksi_tipe }}</td>
                        <td>
                          <div
                            v-if="value.id_divisi && value.status == 'aktif'"
                            class="green-bedge d-inline-block px-3"
                          >
                            Aktif
                          </div>
                          <div v-else class="red-bedge d-inline-block px-3">
                            Nonaktif
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div
                  class="table-responsive mt-3"
                  v-show="this.label == 'Tambah Departemen'"
                >
                  <table
                    class="table table-bordered table-striped table-hover"
                    id="tableHistoryAddress"
                  >
                    <thead>
                      <tr>
                        <th>Nama</th>
                        <th>Tipe Transaksi</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value, index) in listPenomoran"
                        :key="index"
                        style="cursor: default"
                      >
                        <td>{{ value.nama }}</td>
                        <td>{{ value.transaksi_tipe }}</td>
                        <td>
                          <div
                            v-if="value.id_divisi && value.status == 'aktif'"
                            class="green-bedge d-inline-block"
                          >
                            Aktif
                          </div>
                          <div v-else class="red-bedge d-inline-block">
                            Nonaktif
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
// import FooterDashboard from '../src/components/Footer.vue'
import Select2 from "vue3-select2-component";
import SidebarDashboard from "../../../../src/components/Sidebar.vue";
import NavbarDashboard from "../../../../src/components/Navbar.vue";
import { loadScript } from "vue-plugin-load-script";
loadScript("/assets/js/stisla.js");
loadScript("/assets/js/scripts.js");
loadScript("/assets/js/custom.js");
loadScript("/assets/js/page/index.js");

import {
  get_ListKabupaten,
  get_ListKecamatan,
  get_ListProvinsi,
} from "../../../actions/master";
import { checkRules, cksClient, showAlert, checkApp } from "../../../helper";
import {
  get_DepartementDetail,
  post_DepartementSave,
  get_DepartementPenomoran,
} from "../../../actions/company/departement";
import $ from "jquery";
import { maska } from "maska";
import { ROLE } from "../../../constans/Url";
import { get_AkunList } from "../../../actions/akun_perkiraan";
import { get_listAccountSetting } from "../../../actions/accountSetting";

export default {
  name: "DashboardLayout",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
  },

  directives: {
    maska,
  },

  data() {
    return {
      enableSync: checkApp('rawuh') ? true : false,
      hideBtn: false,
      dataRole: ROLE,
      collapseAll: false,
      validated: 1,
      myValue: "aaaa",
      statusCompany: [
        { id: "aktif", text: "Aktif" },
        { id: "tidak", text: "Nonaktif" },
      ], // or [{id: key, text: value}, {id: key, text: value}]
      optionPeriode: [
        { id: "1", text: "Januari" },
        { id: "2", text: "Februari" },
        { id: "3", text: "Maret" },
        { id: "4", text: "April" },
        { id: "5", text: "Mei" },
        { id: "6", text: "Juni" },
        { id: "7", text: "Juli" },
        { id: "8", text: "Agustus" },
        { id: "9", text: "September" },
        { id: "10", text: "Oktober" },
        { id: "11", text: "November" },
        { id: "12", text: "Desember" },
      ],
      OptionsRegion: [],
      OptionsDistrict: [],
      OptionsProvince: [],
      id_departement: this.$route.params.id ? this.$route.params.id : "",
      label: this.$route.params.id ? "Edit Departemen" : "Tambah Departemen",
      label_path: this.$route.params.id ? "Edit" : "Tambah",
      formData: {
        id: this.$route.params.id ? this.$route.params.id : "",
        id_company: cksClient().get("_account").id_company,
        nama: "",
        alamat: "",
        email: "",
        no_telp: "",
        id_kecamatan: "",
        id_kabupaten: "",
        id_provinsi: "",
        status: "aktif",
        isSync: false,
      },
      rules: {
        nama: {
          required: true,
        },
        alamat: {
          required: true,
        },
        id_kecamatan: {
          required: true,
        },
        id_kabupaten: {
          required: true,
        },
        id_provinsi: {
          required: true,
        },
        status: {
          required: true,
        },
        email: {
          required: false,
          email: true,
        },
        no_telp: {
          required: false,
        },
      },
      formError: [],
      isSubmit: false,
      listPenomoran: [],
    };
  },
  created() {
    this.getPenomoran();
    if (this.id_departement) {
      this.getDepartement();
    }
    this.getProvinsi();
  },

  mounted() {
    $(".tab-pane.fade").removeClass("show active");
    if (localStorage.getItem("_numberTab")) {
      setTimeout(() => {
        this.validated = 2;
        $("#pills-profile-tab").tab("show");
        $("#pills-profile").addClass("show active");
        localStorage.removeItem("_numberTab");
      }, 600);
    } else {
      setTimeout(() => {
        $("#pills-home-tab").tab("show");
        $("#pills-home").addClass("show active");
      }, 600);
    }
    this.tooltip();
  },
  methods: {
    setAccess() {
      this.collapseAll = this.hak_akses;
      this.$emit("update-access", this.hak_akses);
    },

    hideButton(data) {
      if (data == false) {
        this.validated = 1;
      } else {
        this.validated = 2;
      }
      this.hideBtn = data;
    },
    editable() {
      this.validated = 2;
    },
    getPenomoran() {
      var params = {id_divisi: this.id_departement};
      get_DepartementPenomoran(params, (res) => {
        this.listPenomoran = res.list;
      });
    },
    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    detail(value) {
      console.log(value);
      $('[data-toggle="tooltip"]').tooltip("hide");
      if (value.id && value.id_divisi) {
        this.$router.push({
          name: "NumberingDetailsDepartement",
          params: {
            id_item: value.id,
            id_divisi: this.id_departement,
          },
        });
      } else {
        $('[data-toggle="tooltip"]').tooltip("hide");
        this.$router.push({
          name: "addNumberingDepartement",
          params: {
            id_divisi: this.id_departement,
            id_tipe: value.id_tipe
          },
        });
      }
      // this.$router.push({
      //   name: "NumberingDetailsDepartement",
      //   params: {
      //     id_divisi: this.id_departement,
      //     id_tipe: value.id_tipe,
      //     divisi: value.id_divisi,
      //   },
      // });
    },
    addNumbering() {
      $('[data-toggle="tooltip"]').tooltip("hide");
      this.$router.push({
        name: "addNumberingDepartement",
        params: {
          id_divisi: this.id_departement,
        },
      });
    },
    clickCancel() {
      this.$router.push({ name: "dataDepartement" });
    },
    async getDepartement() {
      await get_DepartementDetail(this.id_departement, (res) => {
        this.formData.id = res.id;
        this.formData.nama = res.nama;
        this.formData.status = res.status;
        this.formData.id_company = res.id_company;
        this.formData.email = res.email;
        this.formData.no_telp = res.no_telp;
        this.formData.id_provinsi = res.id_provinsi;
        this.getKabupaten(res.id_provinsi);
        this.formData.id_kabupaten = res.id_kabupaten;
        this.getKecamatan(res.id_kabupaten);
        this.formData.id_kecamatan = res.id_kecamatan;
        this.formData.alamat = res.alamat;
      });
    },
    async formChange(key) {
      if (this.rules[key]) {
        this.rules[key].changed = true;
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },
    async getProvinsi() {
      await get_ListProvinsi(
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            var data = list[key];
            data.text = list[key].nama;
            arr.push(data);
          }
          this.OptionsProvince = arr;
        },
        () => {
          this.OptionsProvince = [];
        }
      );
    },
    async getKabupaten(id) {
      await get_ListKabupaten(
        id,
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            var data = list[key];
            data.text = list[key].nama;
            arr.push(data);
          }
          this.OptionsRegion = arr;
        },
        () => {
          this.OptionsRegion = [];
        }
      );
    },

    async getKecamatan(id) {
      await get_ListKecamatan(
        id,
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            var data = list[key];
            data.text = list[key].nama;
            arr.push(data);
          }
          this.OptionsDistrict = arr;
        },
        () => {
          this.OptionsDistrict = [];
        }
      );
    },
    locationEvent(val, key) {
      this.formChange(key);
      if (key === "id_provinsi") {
        this.OptionsDistrict = [];
        this.OptionsRegion = [];
        this.getKabupaten(val.id);
      } else if (key === "id_kabupaten") {
        this.OptionsDistrict = [];
        this.getKecamatan(val.id);
      }
    },
    myChangeEvent(val, key) {
      console.log(val);
      this.formChange(key);
    },
    postData() {
      this.isSubmit = true;
      // var data = { ...this.formData };
      post_DepartementSave(
        this.formData,
        (res) => {
          this.isSubmit = false;
          var msg = "";
          if (res.response_code === 201) {
            msg = "Data Departement berhasil ditambahkan";
          } else {
            msg = "Data Departement berhasil diperbarui";
          }
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            showCancelButton: false,
            showConfirmButton: true,
            onSubmit: () => {
              this.$router.push({ name: "dataDepartement" });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (this.rules[key]) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      var checkAkun = {
        success: false,
        error: [],
      };
      if (this.label_path == "Tambah") {
        checkAkun = {
          success: true,
          error: [],
        };
      } else {
        for (const key in this.rulesAkun) {
          if (Object.hasOwnProperty.call(this.rulesAkun, key)) {
            this.rulesAkun[key].changed = true;
          }
        }
        checkAkun = await checkRules(this.rulesAkun, this.formDataAkun);
        this.formErrorAkun = checkAkun.error;
      }
      if (check.success && checkAkun.success) {
        this.formData.isSync = false
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.id
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      } else if (check.success && !checkAkun.success) {
        $("#pills-account-tab").tab("show");
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Atur Default akun perkiraan terlebih dahulu sebelum melanjutkan",
          showCancelButton: true,
          showConfirmButton: false,
        });
      }
    },
    async onSync() {
      for (const key in this.rules) {
        if (this.rules[key]) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      var checkAkun = {
        success: false,
        error: [],
      };
      if (this.label_path == "Tambah") {
        checkAkun = {
          success: true,
          error: [],
        };
      } else {
        for (const key in this.rulesAkun) {
          if (Object.hasOwnProperty.call(this.rulesAkun, key)) {
            this.rulesAkun[key].changed = true;
          }
        }
        checkAkun = await checkRules(this.rulesAkun, this.formDataAkun);
        this.formErrorAkun = checkAkun.error;
      }
      if (check.success && checkAkun.success) {
        this.formData.isSync = true
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.id
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      } else if (check.success && !checkAkun.success) {
        $("#pills-account-tab").tab("show");
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Atur Default akun perkiraan terlebih dahulu sebelum melanjutkan",
          showCancelButton: true,
          showConfirmButton: false,
        });
      }
    },
    //default akun

    getAkun(type = "") {
      var id_type = "";
      var not_type = false;
      if (type == "piutang") {
        id_type = "2";
      } else if (type == "beban") {
        id_type = "14,15,17";
      } else if (type == "pajak") {
        id_type = "8,9,14,15,19";
      } else if (type == "pendapatan") {
        id_type = "12,16";
      } else if (type == "hutang") {
        id_type = "8";
      } else if (type == "aset_penyusutan") {
        id_type = "6";
      } else if (type == "beban_penyusutan") {
        id_type = "14,15";
      } else if (type == "kasbon") {
        id_type = "1";
        not_type = true;
      } else if (type == "modal") {
        id_type = "11";
      }
      get_AkunList(
        {
          id_company: this.formData.id_company,
          tipe: id_type,
          not_type: not_type,
          id_divisi: this.formData.id,
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }
          if (type != "") {
            this.listOption[type] = data;
          }
        }
      );
    },
    getListAkun() {
      get_listAccountSetting(
        {
          id_company: this.formData.id_company,
          type: "akun_perkiraan",
          id_divisi: this.formData.id,
        },
        (res) => {
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              if (Object.hasOwnProperty.call(this.formDataAkun, element.nama)) {
                this.formDataAkun[element.nama] = element.nilai;
              }
            }
          }
        }
      );
    },

    async formChangeAkun(key) {
      if (Object.hasOwnProperty.call(this.rulesAkun, key)) {
        this.rulesAkun[key].changed = true;
      }
      var check = await checkRules(this.rulesAkun, this.formDataAkun);
      this.formErrorAkun = check.error;
    },

    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-4" : state.sub_level == 1 ? "pl-3" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.header {
  background-color: #eceff1;
  padding: 10px 24px;
  border-radius: 5px;
}

.header button {
  color: #000;
  text-decoration: none;
  font-size: 17px;
  font-weight: 600;
}
.access-item {
  padding: 20px 0px 20px 10px;
  border-radius: 5px;
  position: relative;
  margin-top: 10px;
}

.title-item {
  font-weight: 600;
  font-size: 17px;
}

.all-check {
  position: absolute;
  left: 15px;
  top: 27px;
}
.btn-import {
  background: #00c8bc;
  color: white;
  font-weight: 400;
  border-radius: 3px;
}
.btn-import:hover {
  background: #01a197;
}
</style>
