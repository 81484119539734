import { API } from "../../../constans/Url"
import { apiClient } from "../../../helper"

const get_DepartementList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.DEPARTEMENT.LIST, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_DepartementDetail = (id, callbackSuccess, callbackError) => {
  apiClient(true).get(API.DEPARTEMENT.GET, {id: id}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_DepartementSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.DEPARTEMENT.SAVE, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_DepartementPenomoran = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.DEPARTEMENT.PENOMORAN, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_DepartementPenomoranDetail = (params = {
  id_tipe: '',
  id_divisi: '',
}, callbackSuccess, callbackError) => {
  apiClient(true).get(API.DEPARTEMENT.PENOMORAN_GET, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_DepartementPenomoranSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.DEPARTEMENT.PENOMORAN_SAVE, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  get_DepartementList,
  get_DepartementDetail,
  post_DepartementSave,
  get_DepartementPenomoran,
  get_DepartementPenomoranDetail,
  post_DepartementPenomoranSave
}